import React, {
    useEffect, useState
} from "react";
import { FaSearch } from 'react-icons/fa';
import { ForwardRefInput } from "../../Form/Fields";
import useCarStore from '@/Store/useCarStore';
import { getAllMakes, getModelByMakes } from "@/Services/api";
import { SelectPicker, TagPicker } from "rsuite";
import { toast } from "react-toastify";

const ModelFilter = ({
    selectedMake,
    loadingModels,
    setStore,
    data
}) => {

    const modelsFromStore = useCarStore((state) => state.tempSearchQuery.models);

    const handleChange = (val) => {
        setStore('models', val);
    };


    return < div className="flex flex-col gap-1 tg_text w-full" >
        <label className='text-base'>Оберіть моделі</label>

        <TagPicker
            multiple
            className="flex w-full tg_text tg_bg_secondary border-0 text-base"
            placeholder="Оберіть моделі"
            value={modelsFromStore}
            onChange={handleChange}
            disabled={!selectedMake}
            loading={loadingModels}
            data={data}
            tagProps={{
                className: "tg_text tg_bg_secondary border-2",
            }}
        />
    </div >
}

const MakeFilter = ({
    makes, setSelectedMake, loadingMakes
}) => {

    const selectedMake = useCarStore((state) => state.tempSearchQuery.make);

    return <div className="flex flex-col gap-1 tg_text w-full">
        <label className='text-base'>Оберіть марку</label>

        <SelectPicker
            className="flex w-full tg_text tg_bg_secondary border-0 text-base"
            data={makes}
            placeholder="Оберіть марку"
            onChange={setSelectedMake}
            searchable
            value={selectedMake}
            loading={loadingMakes}
        />
    </div>
}

const SearchFilter = ({ searchInputRef }) => {
    // tempSearchQuery: {
    //     make: '',
    //     models: [],
    //   },
    const changeTempSearchQuery = useCarStore((state) => state.changeTempSearchQuery);
    const selectedMake = useCarStore((state) => state.tempSearchQuery.make);
    // === Стан для марок ===
    const [makes, setMakes] = useState([]);             // список доступних марок
    const [loadingMakes, setLoadingMakes] = useState(false); // Прелоадер для марок

    // === Стан для моделей ===
    const [models, setModels] = useState([]);           // список доступних моделей
    // const [selectedModels, setSelectedModels] = useState([]); // поточно обрана модель
    const [loadingModels, setLoadingModels] = useState(false);   // Прелоадер для моделей

    useEffect(() => {
        setLoadingMakes(true); // вмикаємо прелоадер для марок

        getAllMakes()
            .then((data) => {
                console.log("data: ", data)
                setMakes(data);
            })
            .catch((error) => {
                toast.error('Помилка при завантаженні списку марок:', error);
                console.error('Помилка при завантаженні списку марок:', error);
            })
            .finally(() => {
                setLoadingMakes(false); // вимикаємо прелоадер
            });
    }, []);


    useEffect(() => {
        // Якщо марка ще не обрана — очищуємо моделі
        if (!selectedMake) {
            setModels([]);
            changeTempSearchQuery('models', []);
            return;
        }

        setLoadingModels(true); // вмикаємо прелоадер для моделей
        getModelByMakes(selectedMake)
            .then((data) => {
                setModels(data);
            })
            .catch((error) => {
                console.error('Помилка при завантаженні списку моделей:', error);
            })
            .finally(() => {
                setLoadingModels(false); // вимикаємо прелоадер
            });

    }, [selectedMake]);

    // console.log("makes: ", makes)
    return (
        <div className="flex flex-col gap-4 flex-wrap w-full mb-6">
            {/* SelectPicker для вибору марки (single) */}
            <MakeFilter
                makes={makes.map((m) => ({
                    label: m.Make,
                    value: m.Make
                }))}
                setSelectedMake={(value) => {
                    changeTempSearchQuery('make', value);
                    changeTempSearchQuery('models', []);
                }}
                loadingMakes={loadingMakes}
            />

            {/* SelectPicker для вибору моделі (multiple) */}
            <ModelFilter
                selectedMake={selectedMake}
                loadingModels={loadingModels}
                setStore={changeTempSearchQuery}
                data={models.map((mod) => ({
                    label: mod.Model,
                    value: mod.Model
                }))}
            />
        </div>
    );
};

export default SearchFilter;