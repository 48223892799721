import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Layout from './Components/Layout/Layout';
import Home from './Pages/Home';
import './App.css';
import Profile from './Pages/Profile';
import CarPage from './Pages/CarPage';
import CreateAnnouncement from './Pages/CreateAnnouncement';
import EditAd from './Pages/EditAd/EditAd';
import { useTelegram } from './Hooks/useTelegram';


const pageVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: -20,
  },
};

const pageTransition = {
  duration: 0.5,
};

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Layout>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Home />
              </motion.div>
            }
          />
          <Route
            path="/profile"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Profile />
              </motion.div>
            }
          />
          {/* CarPage */}
          <Route
            path="/car/:id"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <CarPage />
              </motion.div>
            }
          />
          {/* CreateAnnouncement */}
          <Route
            path="/create"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <CreateAnnouncement />
              </motion.div>
            }
          />

          {/* EditAd */}
          <Route
            path="/edit/:id"
            element={
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <EditAd />
              </motion.div>
            }
          />
        </Routes>
      </Layout>
    </AnimatePresence>
  );
};

function App() {
  const { tg } = useTelegram();
  useEffect(() => {
   if (!tg) return;

   tg.disableVerticalSwipes();
   tg.lockOrientation();
  }, [tg]);

  return (
    <AnimatedRoutes />
  );
}

export default App;
