import React, { useRef, useCallback, useState, useEffect } from "react";
import { FaUserCircle, FaFilter, FaSearch } from 'react-icons/fa';
import { Link } from "react-router-dom";
import useCarStore from '@/Store/useCarStore';
import { filterSchema } from "@/Utils/validationSchema";
import { ForwardRefInput } from "../Form/Fields";
import HeaderFilter from "./HeaderFilter";
import { filterTypes } from "@/Utils/optionsTypes";
import { Formik } from "formik";
// import { SlSpeedometer } from "react-icons/sl";
// import { IoLocationOutline } from "react-icons/io5";
// import { PiEngineLight } from "react-icons/pi";
// import { TbManualGearbox } from "react-icons/tb";
// import { FaOilCan } from "react-icons/fa";
// import { ReactComponent as CarChassis } from "@/Utils/CarChassis.svg";
// import { validationSchema } from "@/Utils/validationSchema";


const Header = () => {
    const { searchQuery } = useCarStore();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const searchInputRef = useRef(null);

    const toggleDrawer = useCallback(() => {
        setIsDrawerOpen(!isDrawerOpen);
    }, [isDrawerOpen]);


    useEffect(() => {
        if (isDrawerOpen) {
            // body overflow hidden
            document.body.style.overflow = 'hidden';

            setTimeout(() => {
                if (searchInputRef.current) {
                    searchInputRef.current.focus();
                }
            }, 300); // Устанавливаем фокус с небольшой задержкой
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [isDrawerOpen]);


    return (<div>
        <div className="flex items-center justify-between mb-4 shadow-md px-4 py-2">
            <Link to="/profile">
                <FaUserCircle className="tg_accents text-2xl mr-4" />
            </Link>
            {/* <ForwardRefInput
                type="search"
                name="search"
                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                value={searchQuery}
                onClick={toggleDrawer}
                readOnly
                placeholder="Porsche Macan"
                icon={<FaSearch className="tg_accents text-2xl" />}
            /> */}
            <FaSearch className="tg_accents text-2xl ml-4" onClick={toggleDrawer} />
        </div>
        <HeaderFilter
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            searchInputRef={searchInputRef}
        />
    </div >
    );
};

export default Header;
