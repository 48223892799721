export function wrapLinksInAnchor(description) {
    // Регулярка, яка шукає будь-які URL-адреси, що починаються з http:// або https://
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    
    // Замінимо посилання в тексті на <a>…</a>
    return description.replace(urlRegex, (url) => {
        const domain = new URL(url).hostname;
        return `<br/><br/><a class="text-base tg_link cursor-pointer" href="${url}" target="_blank">${domain}</a>`;
    });
}

