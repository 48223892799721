import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from 'react-router-dom';

import { useTelegram } from "@/Hooks/useTelegram";

import {
    AutoSizer,
    InfiniteLoader,
    List,
    WindowScroller
} from "react-virtualized";

import Header from '@/Components/Layout/Header';
import CarCard from '@/Components/Card/CarCard';
import LoadingSpinner, { SmallSpiner } from "@/Components/Layout/LoadingSpinner";

import useCarStore from '../Store/useCarStore';
import ToTop from "@/Components/Buttons/ToTop";

const Home = () => {
    const { tg } = useTelegram();
    const {
        carAds,
        resetFilters,
        fetchCarAds,
        filters,
        total,
        firstLoad,
        setFirstLoad,
        loading,
        error,
        page,
        itemsPerPage,
        changePage,
        searchQuery,
        changeSearchQuery,
        setLoading,
    } = useCarStore();

    const [isFetching, setIsFetching] = useState(false);
    const prevFilters = useRef(filters);
    const prevSearchQuery = useRef(searchQuery);

    // ===========================================
    //           Функція завантаження даних
    // ===========================================
    const loadMoreCars = useCallback(
        async (reset = false) => {
            // Якщо вже триває завантаження або все вже завантажено
            if (isFetching || loading || (carAds.length >= total && page !== 1)) {
                return;
            }

            setIsFetching(true);

            try {
                const currentPage = reset ? 1 : page;
                const data = await fetchCarAds(currentPage, itemsPerPage, reset);
                console.log("fetch", data);

                // Якщо робимо reset, то наступна сторінка буде 2, інакше збільшуємо на 1
                changePage(reset ? 2 : currentPage + 1);

                tg?.HapticFeedback?.notificationOccurred("success");
            } catch (err) {
                tg?.HapticFeedback?.notificationOccurred("error");
            } finally {
                setIsFetching(false);
                setLoading(false);
            }
        },
        [
            isFetching,
            loading,
            carAds.length,
            total,
            page,
            itemsPerPage,
            fetchCarAds,
            changePage,
            tg,
            setLoading,
        ]
    );

    // Скидаємо фільтри й дані
    const clearFilters = () => {
        changeSearchQuery({
            make: '',
            models: [],
        });
        resetFilters();
        loadMoreCars(true);
    };

    // Початкове завантаження
    useEffect(() => {
        if (carAds.length === 0) {
            console.log("fetch first");
            loadMoreCars();
            setFirstLoad(false);
        }
        return () => {
            setLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Завантаження при зміні фільтрів або пошукового запиту
    useEffect(() => {
        const filtersChanged =
            JSON.stringify(prevFilters.current) !== JSON.stringify(filters);
        const searchQueryChanged = prevSearchQuery.current !== searchQuery;

        if (filtersChanged || searchQueryChanged) {
            loadMoreCars(true); // скидаємо
        }
        prevFilters.current = filters;
        prevSearchQuery.current = searchQuery;
    }, [filters, searchQuery]);

    if (loading && firstLoad) return <LoadingSpinner />;

    if (error)
        return (
            <div className="min-h-screen p-4 font-bold text-center tg_text flex items-center justify-center">
                Сталася помилка, спробуйте пізніше або зверніться до адміністратора
            </div>
        );

    // 1. isRowLoaded: перевірка, чи дані для поточного індексу вже завантажені
    const isRowLoaded = ({ index }) => {
        return !!carAds[index];
    };
    const loadMoreRows = isFetching
        ? () => Promise.resolve()
        : async ({ startIndex, stopIndex }) => {
            // Викликаємо вашу функцію для завантаження наступної порції
            await loadMoreCars();
        };

    const rowRenderer = ({
        key,
        index,
        style
    }) => {
        // Якщо даних ще немає (відбувається догрузка), можна показати лоадер
        if (!carAds[index]) {
            return (
                <div key={key} style={style}>
                    <SmallSpiner />
                </div>
            );
        }

        const car = carAds[index];
        return (
            <Link
                key={car.id}
                to={`/car/${car.id}`}
                style={style}
                className="hover:text-inherit hover:decoration-none"
            >
                <CarCard car={car} />
            </Link>
        );
    };

    // Якщо список порожній (і це не initial loading) — показуємо “Нічого не знайдено”
    if (!carAds.length && !loading && !isFetching && !firstLoad) {
        return (
            <div className="App tg_bg">
                <Header />
                <div className="flex justify-center">
                    <Link
                        to="/create"
                        className="w-full mx-4 justify-center flex items-center gap-2 tg_btn_color tg_btn_text uppercase font-semibold px-4 py-4 items-center rounded"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        Створити оголошення
                    </Link>
                </div>
                <div className="min-h-screen p-4 flex flex-col gap-4">
                    <h1 className="text-2xl font-bold text-center tg_text">Нічого не знайдено</h1>
                    <button
                        onClick={clearFilters}
                        className="tg_btn_color tg_btn_text uppercase font-semibold px-4 py-4 items-center rounded"
                    >
                        Повернутися на початок
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="App tg_bg">
            <Header />
            {/* Кнопка "Створити оголошення" */}
            <div className="flex justify-center">
                <Link
                    to="/create"
                    className="w-full mx-4 justify-center flex items-center gap-2 tg_btn_color tg_btn_text uppercase font-semibold px-4 py-4 items-center rounded"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                    </svg>
                    Створити оголошення
                </Link>
            </div>

            <div className="min-h-screen p-4">
                {/* Обгортка для віртуалізованого списку */}
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <WindowScroller>
                            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                                <InfiniteLoader
                                    isRowLoaded={isRowLoaded}
                                    loadMoreRows={loadMoreRows}
                                    // rowCount = загальна кількість елементів, які "в теорії" можуть бути
                                    // аби InfiniteLoader знав, коли зупинитися
                                    rowCount={total}
                                >
                                    {({ onRowsRendered, registerChild }) => (
                                        <List
                                            autoHeight
                                            ref={registerChild}
                                            onRowsRendered={onRowsRendered}
                                            height={height}
                                            isScrolling={isScrolling}
                                            onScroll={onChildScroll}
                                            scrollTop={scrollTop}
                                            width={width}
                                            rowCount={carAds.length}
                                            rowHeight={420} // Фіксована висота ряду (приклад)
                                            rowRenderer={rowRenderer}
                                        />
                                    )}
                                </InfiniteLoader>
                            )}
                        </WindowScroller>
                    )}
                </AutoSizer>

                <ToTop />
                {/* Якщо у нас іде догрузка (isFetching = true), показуємо маленький спінер знизу */}
                {isFetching && (
                    <div className="flex justify-center my-4">
                        <SmallSpiner />
                    </div>
                )}

                {/* Якщо ми догортали до кінця вивести повідомлення */}
                {carAds.length >= total && !isFetching && !loading && !firstLoad && (
                    <div className="text-center w-full rounded-md shadow-md p-4 ">
                        <p className="tg_text">Немає нових оголошень</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Home;
