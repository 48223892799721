import React, { useEffect, useCallback, useState } from "react";
import MainInfoTab from "./MainInfoTab";
import PhotoTab from "./PhotoTab";
import { useParams } from "react-router-dom";
import { HapticButton } from "@/Components/Buttons/Button";
import { getCarAdById } from "@/Services/api";
import { useTelegram } from "@/Hooks/useTelegram";

const EditAd = () => {
    const [activeTab, setActiveTab] = useState("mainInfo");
    const [adData, setAdData] = useState({});
    const { id } = useParams();
    const { tg } = useTelegram();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const getAdsData = async () => {
        try {
            const res = await getCarAdById(id);
            setAdData(res);
            tg?.HapticFeedback?.notificationOccurred('success');
        } catch (error) {
            console.log(error);
            tg?.HapticFeedback?.notificationOccurred('error');
        }

    }

    useEffect(() => {
        if (id) {
            getAdsData();
        }
    }, [id]);


    return (
        <div className="min-h-screen py-4">
            <h1 className="text-2xl font-bold tg_text mb-4 p-2">Редагувати оголошення
                #{id}<br />
                {adData.make} {adData.model} {adData.car_year}
            </h1>
            {/* Таби */}
            <div className="tabs flex mb-4">
                <HapticButton
                    className={`tab-button px-4 py-2 mr-2 ${activeTab === "mainInfo" ? "bg-blue-500 text-white" : "bg-gray-200"
                        }`}
                    onClick={() => handleTabChange("mainInfo")}
                >
                    Основна інформація
                </HapticButton>
                <HapticButton
                    className={`tab-button px-4 py-2 ${activeTab === "photos" ? "bg-blue-500 text-white" : "bg-gray-200"
                        }`}
                    onClick={() => handleTabChange("photos")}
                >
                    Фото
                </HapticButton>
            </div>

            {/* Контент табів */}
            <div className="tab-content">
                {activeTab === "mainInfo" && <MainInfoTab adData={adData} setAdData={setAdData} />}
                {activeTab === "photos" && <PhotoTab
                    files={adData.photos}
                />}
            </div>
        </div>
    );
};

export default EditAd;
