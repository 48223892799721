// store/useCarStore.js
import { create } from 'zustand'
import { devtools } from 'zustand/middleware';
import { getCarAds, getCarAdById, createCarAd, updateCarAd, deleteCarAd } from '@/Services/api';
import { filterTypes } from '@/Utils/optionsTypes';

const useCarStore = create(
  devtools((set, get) => ({
    firstLoad: true,
    carAds: [],
    searchQuery: {
      make: '',
      models: [],
    },
    tempSearchQuery: {
      make: null,
      models: []  // <-- важливо, щоб був масив
    },
    changeTempSearchQuery: (key, value) =>
      set((state) => ({
        tempSearchQuery: {
          ...state.tempSearchQuery,
          [key]: value
        }
      })),

    total: 0,
    page: 1,
    itemsPerPage: 15,
    loading: false,
    error: null,
    srchBtnClicked: false,
    filters: {
      price_min: 0,
      price_max: 0,
      car_year_min: 0,
      car_year_max: 0,
      discounted_price_min: 0,
      discounted_price_max: 0,
      motor_min: 0.0,
      motor_max: 0.0,
      mileage_min: 0,
      mileage_max: 0,
      drive_type: '',
      location: '',
    },
    // Тимчасові фільтри для форми
    tempFilters: {
      price_min: 0,
      price_max: 150000,
      car_year_min: 0,
      car_year_max: 0,
      motor_min: 0.0,
      motor_max: 0.0,
      mileage_min: 0,
      mileage_max: 0,
      drive_type: '',
      location: '',
    },
    // Функція для оновлення тимчасових фільтрів
    setTempFilters: (_filters) =>
      set((state) => ({
        tempFilters: {
          ...state.tempFilters,
          ..._filters,
        },
      })),
    // Застосування фільтрів (перенос тимчасових фільтрів в активні)
    applyFilters: () =>
      set((state) => ({
        filters: { ...state.tempFilters },
        searchQuery: state.tempSearchQuery,
        page: 1,
      })),
    setFilters: (_filters) => set((state) => ({
      filters: {
        ...state.filters,
        ..._filters
      }
    })),
    setLoading: (loading) => set({ loading }),
    changePage: (page) => set({ page }),
    changeSearchQuery: (searchQuery) => set({ searchQuery }),

    setSrchBtnClicked: (srchBtnClicked) => set({ srchBtnClicked }),
    resetFilters: () => set(
      (state) => ({
        filters: {
          price_min: 0,
          price_max: 0,
          car_year_min: 0,
          car_year_max: 0,
          discounted_price_min: 0,
          discounted_price_max: 0,
          motor_min: 0.0,
          motor_max: 0.0,
          mileage_min: 0,
          mileage_max: 750,
          drive_type: '',
          location: '',
        },
        tempFilters: {
          price_min: 0,
          price_max: 0,
          car_year_min: 0,
          car_year_max: 0,
          motor_min: 0.0,
          motor_max: 0.0,
          mileage_min: 0,
          mileage_max: 750,
          drive_type: '',
          location: '',
        },
        searchQuery: {
          make: '',
          models: [],
        },
        tempSearchQuery: {
          make: '',
          models: [],
        },
        page: 1,
      })),

    filterDefaultValues: (filters) => {
      const processedValues = Object.keys(filters).reduce((acc, key) => {
        let value = filters[key];

        // Приведение значения к нужному типу
        if (filterTypes[key] === 'number') {
          value = value !== '' ? Number(value) : null;
        }

        // Исключаем пустые значения, нули и т.д.
        if (value !== '' && value !== null && value !== undefined && value !== 0) {
          acc[key] = value;
        }

        return acc;
      }, {});

      return processedValues;
    },

    setFirstLoad: (firstLoad) => set({ firstLoad }),
    fetchCarAds: async (page, itemsPerPage, reset = false) => {
      set({ loading: true, error: null });
      try {
        const { filters, searchQuery } = get();
        const filteredFilters = get().filterDefaultValues(filters);
        const data = await getCarAds(page, itemsPerPage, searchQuery, filteredFilters);
        const carAds = Array.isArray(data.car_ads) ? data.car_ads : [];

        set(state => ({
          carAds: reset ? carAds : [...state.carAds, ...carAds],
          total: data.total || 0,
          loading: false,
          error: null,
        }));

        return data;

      } catch (error) {
        set({ loading: false, error: error.message });
        throw error;
      }

    },
    // Другие функции для getCarAdById, createCarAd, updateCarAd, deleteCarAd
  })));

export default useCarStore;
