import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PhotoSlider from "../Components/Card/PhotoSlider";
import LoadingSpinner from "@/Components/Layout/LoadingSpinner";
import { getCarAdById } from '@/Services/api';
import { SlSpeedometer } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { PiEngineLight } from "react-icons/pi";
import { TbManualGearbox } from "react-icons/tb";
import { SlEnergy } from "react-icons/sl";
import { convertToUAH, convertToUSD, formatCurrency } from '@/Utils/currency';
import { formatNumber } from '@/Utils/numberFormat';
import { useTelegram } from '@/Hooks/useTelegram';
import { toast } from 'react-toastify';
import { filterTgUser } from '@/Utils/optionsTypes';
import { wrapLinksInAnchor } from '@/Utils/textLinkWrap';
import { HapticButton } from '@/Components/Buttons/Button';

const CarPage = () => {
    const { id } = useParams();
    const [car, setCar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { tg } = useTelegram();
    const [convertedPrice, setConvertedPrice] = useState(null);

    useEffect(() => {
        const fetchConvertedPrice = async () => {
            let convertedPrice = null;
            if (car.currency === 'USD' || car.currency === 'EUR') {
                convertedPrice = await convertToUAH(car.price, car.currency);
            } else if (car.currency === 'UAH') {
                convertedPrice = await convertToUSD(car.price);
            }
            setConvertedPrice(convertedPrice?.toFixed(2));
        };
        if (car) fetchConvertedPrice();
    }, [car]);

    useEffect(() => {
        const fetchCarDetails = async () => {
            try {
                const data = await getCarAdById(id);
                setCar(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCarDetails();
    }, [id]);

    const handleNavigateToTg = useCallback(() => {
        if (!car) return;
        const url = `https://t.me/carzonetestingapp/${car.tg_message_id}`;
        tg.openTelegramLink(url);
    }, [car]);

    const linkToUser = (user) => {
        const link = `https://t.me/${user}`;
        tg.openTelegramLink(link);
    };

    // Share button callback
    const handleShare = useCallback(() => {
        if (!car) return;
        const url = `https://t.me/carzone_test_app_bot/carzone_ua/?startapp=ad_id-${car.id}`
        const text = encodeURIComponent(`Перегляньте оголошення: ${car.make} ${car.model} ${car.car_year} $${car.price}`);
        // Відкриваємо t.me/share/url у новому вікні (або в цьому ж - залежить від логіки)
        window.open(`https://t.me/share/url?url=${url}&text=${text}`, '_blank');
        // Або: Telegram.WebApp.openLink(`https://t.me/share/url?url=${url}&text=${text}`);

    }, [car]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!car || !car.tg_message_id) return;
        tg.MainButton.setParams({
            text: 'Відкрити з коментарями',
        });
        tg.MainButton.show();
        tg.MainButton.onClick(handleNavigateToTg);

        return () => {
            tg.MainButton.hide();
            tg.MainButton.offClick(handleNavigateToTg);
        };
    }, [tg, car]);

    const fuelSuffix = (fuel) => fuel.toLowerCase() === 'електро' ? ' кв.ч.' : ' л.';

    if (loading) return <LoadingSpinner />;
    if (error) return <div>Помилка: {error}</div>;

    return (
        <div className="min-h-screen">
            <div className='relative'>
                <PhotoSlider photos={car.photos} />
                <HapticButton
                    onClick={handleShare}
                    className="px-2 py-1 tg_btn_color tg_btn_text rounded flex items-center gap-2 absolute bottom-2 right-2 z-10"
                    hapticFeedback="light" // Відтворити вібрацію
                >
                    Поділитися
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                    </svg>
                </HapticButton>
            </div>
            {/* Share button */}

            <div className="flex gap-4 p-2 flex-col">
                <div className="tg_section_bg rounded-lg p-2">
                    <div className="flex justify-between mb-2 flex-col">
                        <h2 className="text-lg font-bold tg_text">
                            {car.make} {car.model} {car.car_year}
                        </h2>
                        <div className="flex items-center gap-2">
                            <span className="text-green-700 text-lg font-semibold dark:text-green-500 dark:text-lg dark:font-semibold">
                                {formatCurrency(car.price, car.currency)}
                            </span>
                            {
                                (car.currency === 'USD' || car.currency === 'EUR') ? (
                                    <span className="text-sm tg_text_hint">~{formatCurrency(convertedPrice, 'UAH')}</span>
                                ) : (
                                    <span className="text-sm tg_text_hint">~{formatCurrency(convertedPrice, 'USD')}</span>
                                )
                            }
                            {car.is_negotiable && <span className="text-sm tg_text_hint border-2 border-blue-500 rounded-md dark:border-blue-800">Торг</span>}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 tg_text text-sm gap-y-4 dark:gap-y-4 p-2">
                        <div className="flex items-center gap-2">
                            <SlSpeedometer className="text-xl" />
                            <span>{formatNumber(car.mileage)} тис. км</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <IoLocationOutline className="text-xl" />
                            <span>{car.location}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            {
                                car.fuel.toLowerCase() === 'електро'
                                    ? <SlEnergy className="text-xl" />
                                    : <PiEngineLight className="text-xl" />
                            }
                            <span>{car.fuel} - {car.motor}{fuelSuffix(car.fuel)}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <TbManualGearbox className="text-xl" />
                            <span>{car.gearbox}</span>
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-4 text-sm tg_text_hint ">
                        {
                            car.car_number && (
                                <div className="flex items-center border-2 border-blue-500 rounded-md dark:border-blue-800 max-w-1/2">
                                    <span className="mr-2 bg-blue-500 px-1 dark:bg-blue-800 text-white">UA</span>
                                    <span className="pr-2 truncate">{car.car_number}</span>
                                </div>
                            )
                        }
                        {
                            car.vincode && (
                                <span className="border-2 px-2 border-blue-500 rounded-md dark:border-blue-800">
                                    {car.vincode}
                                </span>
                            )
                        }
                    </div>
                    <div className="mt-2 text-left tg_text_hint text-sm">
                        <p>{new Date(car.created_at).toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="tg_section_bg rounded-lg tg_text p-2 text-wrap">
                    <h3 className="text-lg font-bold">Опис</h3>
                    <p
                        dangerouslySetInnerHTML={{ __html: wrapLinksInAnchor(car.description) }}
                    />
                </div>

                <div className="tg_section_bg rounded-lg p-2 flex flex-col gap-2">
                    {
                        car.phone && (
                            <div className="flex justify-between items-center">
                                <h3 className="text-lg font-bold tg_text">Контакти</h3>
                                <a
                                    href={`tel:${car.phone}`}
                                    className="flex items-center gap-1 text-base tg_link cursor-pointer"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="size-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                        />
                                    </svg>
                                    {car.phone}
                                </a>
                            </div>
                        )
                    }
                    {
                        car?.user &&
                        !filterTgUser.includes(car.user.TelegramUsername) && (
                            <div className="flex justify-end items-center ">
                                <div
                                    className="flex items-center gap-1 text-base tg_link cursor-pointer"
                                    onClick={() => linkToUser(car.user.TelegramUsername)}
                                >
                                    @{car.user.TelegramUsername}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default CarPage;